// src/pages/LandingPage.jsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Typography,
  Button,
  Container,
  Box,
  Grid,
  Card,
  CardContent,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Brightness4Icon from '@mui/icons-material/Brightness4'; // Dark mode icon
import Brightness7Icon from '@mui/icons-material/Brightness7'; // Light mode icon

const LandingPage = () => {
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));

  // Theme definitions
  const lightTheme = {
  background: '#FFFFFF',       // White background
  primary: '#FFFFFF',          // White for sections
  secondary: '#F9F9F9',        // Very light gray for subtle contrast
  accent: '#4CAF50',           // Green for buttons and accents
  textPrimary: '#212121',      // Dark gray text for readability
  textSecondary: '#757575',    // Medium gray text for secondary content
  cardBackground: '#FFFFFF',   // White cards
  buttonBackground: '#4CAF50', // Green buttons
  buttonHover: '#43A047',      // Slightly darker green on hover
};

  const darkTheme = {
    background: '#0A0A0A',       // Darker background
    primary: '#121212',          // Very dark for sections
    secondary: '#1E1E1E',        // Slightly lighter dark
    accent: '#4CAF50',           // Purple accent for buttons and icons
    textPrimary: '#FFFFFF',      // White text
    textSecondary: '#B0B0B0',    // Light gray text
    cardBackground: '#1E1E1E',   // Slightly lighter dark cards
    buttonBackground: '#4CAF50', // Purple buttons
    buttonHover: '#9A67EA',      // Darker purple on hover
  };

  const [theme, setTheme] = useState('dark');
  const colors = theme === 'dark' ? darkTheme : lightTheme;

  // Toggle theme function
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'dark' ? 'light' : 'dark'));
  };

  return (
    <div style={{ backgroundColor: colors.background, color: colors.textPrimary }}>
      {/* Theme Toggle Button */}
      <Box sx={{ position: 'fixed', top: 16, right: 16, zIndex: 1000 }}>
        <IconButton onClick={toggleTheme} sx={{ color: colors.textPrimary }}>
          {theme === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
      </Box>

      {/* Hero Section */}
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          background: `linear-gradient(135deg, ${colors.primary}, ${colors.secondary})`,
          textAlign: 'center',
          pt: 12,
          pb: 16,
        }}
      >
        <Container maxWidth="md">
          <Typography
            variant="h1"
            sx={{
              fontWeight: 700,
              lineHeight: 1.2,
              mb: 4,
              fontSize: { xs: '2.5rem', sm: '4rem' },
              textTransform: 'uppercase',
              letterSpacing: '-1px',
              color: colors.textPrimary,
            }}
          >
            Unleash Your Potential with FocusHero
          </Typography>
          <Typography
            variant="h5"
            sx={{
              mb: 6,
              fontWeight: 300,
              color: colors.textSecondary,
            }}
          >
            The AI companion designed to empower professionals with ADHD to thrive in the workplace.
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: colors.buttonBackground,
              color: '#FFFFFF',
              borderRadius: '30px',
              fontSize: '1.2rem',
              px: 5,
              py: 1.5,
              boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: colors.buttonHover,
                transform: 'translateY(-3px)',
              },
            }}
            component={Link}
            to="/app/signup"
          >
            Get Started Free
          </Button>
        </Container>
      </Box>

      {/* Problem Statement Section */}
      <Box sx={{ py: 10, backgroundColor: colors.primary }}>
        <Container maxWidth="md">
          <Typography
            variant="h4"
            align="center"
            sx={{ mb: 6, fontWeight: 'bold', color: colors.textPrimary }}
          >
            Overwhelmed by Distractions?
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ mb: 4, color: colors.textSecondary }}
          >
            We understand the challenges that ADHD professionals face: staying focused, managing tasks, and avoiding distractions in a fast-paced work environment.
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ color: colors.textSecondary }}
          >
            FocusHero is here to help you turn those challenges into strengths.
          </Typography>
        </Container>
      </Box>

      {/* Features Section */}
      <Box sx={{ py: 12, backgroundColor: colors.secondary, textAlign: 'center' }}>
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            sx={{ mb: 8, fontWeight: 'bold', color: colors.textPrimary }}
          >
            What Makes FocusHero Unique
          </Typography>
          <Grid container spacing={6}>
            {[
              {
                title: 'Personalized Focus Sessions',
                description:
                  'Customize focus timers and break intervals that suit your work style. Get reminders and rewards to keep you motivated.',
                icon: '⏰',
              },
              {
                title: 'Task Breakdown Tools',
                description:
                  'Easily break down complex projects into manageable tasks with our intuitive interface.',
                icon: '📝',
              },
              {
                title: 'Distraction Alerts',
                description:
                  'Receive gentle nudges when you get off track, helping you refocus and maintain productivity.',
                icon: '🔔',
              },
              {
                title: 'Seamless Integrations',
                description:
                  'Connect FocusHero with your favorite tools like calendars, task managers, and communication apps.',
                icon: '🔗',
              },
            ].map((feature, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card
                  sx={{
                    backgroundColor: colors.cardBackground,
                    boxShadow: '0px 4px 10px rgba(0,0,0,0.5)',
                    borderRadius: '20px',
                    overflow: 'hidden',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-5px)',
                      boxShadow: '0px 8px 20px rgba(0,0,0,0.7)',
                    },
                  }}
                >
                  <CardContent sx={{ textAlign: 'center', p: 4, flexGrow: 1 }}>
                    <div
                      style={{
                        fontSize: '3rem',
                        marginBottom: '16px',
                        lineHeight: 1,
                        color: colors.accent,
                      }}
                    >
                      {feature.icon}
                    </div>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 'bold', mb: 2, color: colors.textPrimary }}
                    >
                      {feature.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: colors.textSecondary }}>
                      {feature.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Detailed Features Section */}
      <Box sx={{ py: 12, backgroundColor: colors.primary }}>
        <Container maxWidth="lg">
          <Grid container spacing={6}>
            {[
              {
                title: 'Focus Sessions Tailored to You',
                description:
                  'Set custom focus periods and breaks to match your natural rhythms, boosting efficiency.',
                image: '/images/focus-sessions.png',
              },
              {
                title: 'Simplify Complex Tasks',
                description:
                  'Break down big projects into smaller, actionable steps to make progress manageable.',
                image: '/images/task-breakdown.png',
              },
              {
                title: 'Stay on Track with Gentle Nudges',
                description:
                  'Our AI monitors your activity and sends subtle reminders when you get distracted.',
                image: '/images/distraction-alerts.png',
              },
              {
                title: 'Integrate with Your Workflow',
                description:
                  'Connect with tools like Google Calendar, Trello, and Slack for a seamless experience.',
                image: '/images/integrations.png',
              },
            ].map((feature, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <img
                      src={feature.image}
                      alt={feature.title}
                      style={{ width: '100%', borderRadius: '10px' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: 'bold', mb: 2, color: colors.textPrimary }}
                    >
                      {feature.title}
                    </Typography>
                    <Typography variant="body1" sx={{ color: colors.textSecondary }}>
                      {feature.description}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Testimonials Section */}
      <Box sx={{ py: 12, backgroundColor: colors.secondary }}>
        <Container maxWidth="md">
          <Typography
            variant="h3"
            align="center"
            sx={{ mb: 8, fontWeight: 'bold', color: colors.textPrimary }}
          >
            Hear from Our Users
          </Typography>
          <Grid container spacing={4}>
            {[
              {
                quote:
                  "FocusHero has transformed the way I work. I'm more productive and less stressed than ever before.",
                name: 'Alex P.',
                position: 'Marketing Manager',
              },
              {
                quote:
                  "As someone with ADHD, staying focused was always a challenge. FocusHero's tools have made a huge difference.",
                name: 'Jamie L.',
                position: 'Software Engineer',
              },
              {
                quote:
                  "I love how intuitive and supportive FocusHero is. It's like having a personal assistant keeping me on track.",
                name: 'Taylor R.',
                position: 'Project Coordinator',
              },
            ].map((testimonial, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Card
                  sx={{
                    backgroundColor: colors.cardBackground,
                    p: 3,
                    borderRadius: '16px',
                    height: '100%',
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontStyle: 'italic', mb: 2, color: colors.textPrimary }}
                  >
                    "{testimonial.quote}"
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 'bold', color: colors.textPrimary }}
                  >
                    {testimonial.name}
                  </Typography>
                  <Typography variant="body2" sx={{ color: colors.textSecondary }}>
                    {testimonial.position}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* FAQ Section */}
      <Box sx={{ py: 12, backgroundColor: colors.primary }}>
        <Container maxWidth="md">
          <Typography
            variant="h3"
            align="center"
            sx={{ mb: 6, fontWeight: 'bold', color: colors.textPrimary }}
          >
            Frequently Asked Questions
          </Typography>
          {[
            {
              question: 'How does FocusHero help with ADHD?',
              answer:
                'FocusHero provides tools specifically designed to address common ADHD challenges like distraction, task initiation, and organization. By offering personalized focus sessions, task breakdowns, and gentle reminders, it helps you stay on track and be more productive.',
            },
            {
              question: 'Is FocusHero easy to integrate with my existing tools?',
              answer:
                'Absolutely! FocusHero seamlessly integrates with popular calendars, task managers, and communication apps to fit smoothly into your workflow.',
            },
            {
              question: 'Is my data secure with FocusHero?',
              answer:
                'Yes, we take your privacy seriously. All your data is encrypted, and we follow industry best practices to keep your information safe.',
            },
          ].map((faq, index) => (
            <Accordion key={index} sx={{ backgroundColor: colors.cardBackground }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: colors.textPrimary }} />}>
                <Typography variant="h6" sx={{ color: colors.textPrimary }}>
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" sx={{ color: colors.textSecondary }}>
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Container>
      </Box>

      {/* Newsletter Signup Section */}
      <Box sx={{ py: 8, backgroundColor: colors.secondary, textAlign: 'center' }}>
        <Container maxWidth="sm">
          <Typography
            variant="h4"
            sx={{ mb: 4, fontWeight: 'bold', color: colors.textPrimary }}
          >
            Stay Updated with FocusHero
          </Typography>
          <Typography
            variant="body1"
            sx={{ mb: 4, color: colors.textSecondary }}
          >
            Sign up for our newsletter to receive productivity tips, resources, and the latest updates.
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Enter your email"
            sx={{
              mb: 2,
              backgroundColor: colors.cardBackground,
              input: { color: colors.textPrimary },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: colors.textSecondary,
                },
                '&:hover fieldset': {
                  borderColor: colors.textPrimary,
                },
                '&.Mui-focused fieldset': {
                  borderColor: colors.textPrimary,
                },
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: colors.buttonBackground,
                      color: '#FFFFFF',
                      '&:hover': {
                        backgroundColor: colors.buttonHover,
                      },
                    }}
                  >
                    Subscribe
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Container>
      </Box>

      {/* Call to Action Section */}
      <Box
        sx={{
          py: 12,
          textAlign: 'center',
          backgroundColor: colors.primary,
        }}
      >
        <Container maxWidth="sm">
          <Typography
            variant="h3"
            sx={{ mb: 4, fontWeight: 'bold', color: colors.textPrimary }}
          >
            Your Journey to Success Starts Here
          </Typography>
          <Typography
            variant="body1"
            sx={{ mb: 6, color: colors.textSecondary }}
          >
            Join a community of professionals transforming their work experience with FocusHero.
          </Typography>
          <Button
            variant="contained"
            sx={{
              px: 6,
              py: 2,
              borderRadius: '30px',
              fontSize: '1.2rem',
              backgroundColor: colors.buttonBackground,
              color: '#FFFFFF',
              boxShadow: '0px 5px 15px rgba(0,0,0,0.5)',
              '&:hover': {
                backgroundColor: colors.buttonHover,
              },
            }}
            component={Link}
            to="/app/signup"
          >
            Get Started Now
          </Button>
        </Container>
      </Box>

      {/* Footer */}
      <Box sx={{ py: 4, backgroundColor: colors.secondary }}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" sx={{ mb: 2, color: colors.textPrimary }}>
                FocusHero
              </Typography>
              <Typography variant="body2" sx={{ color: colors.textSecondary }}>
                Empowering professionals with ADHD to excel in their careers.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="h6" sx={{ mb: 2, color: colors.textPrimary }}>
                Company
              </Typography>
              <Typography variant="body2" sx={{ color: colors.textSecondary }}>
                About Us
              </Typography>
              <Typography variant="body2" sx={{ color: colors.textSecondary }}>
                Careers
              </Typography>
              <Typography variant="body2" sx={{ color: colors.textSecondary }}>
                Blog
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="h6" sx={{ mb: 2, color: colors.textPrimary }}>
                Support
              </Typography>
              <Typography variant="body2" sx={{ color: colors.textSecondary }}>
                Help Center
              </Typography>
              <Typography variant="body2" sx={{ color: colors.textSecondary }}>
                Contact Us
              </Typography>
              <Typography variant="body2" sx={{ color: colors.textSecondary }}>
                Privacy Policy
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" sx={{ mb: 2, color: colors.textPrimary }}>
                Connect with Us
              </Typography>
              {/* Social Media Icons */}
              {/* Add social media icons here using Icon components from MUI */}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default LandingPage;
