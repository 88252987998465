// src/components/Dashboard.js
import React from 'react';
import Typography from '@mui/material/Typography';
import UpcomingMeetings from './UpcomingMeetings'; 
import { Box, Container, Grid } from '@mui/material'; // Import additional components

const Dashboard = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}> 
      <Grid container spacing={3}> 
        <Grid item xs={12}>
          <Box 
            sx={{ 
              p: 3, 
              bgcolor: 'background.paper', 
              borderRadius: 2, 
              boxShadow: 3 
            }}
          >
            <Typography variant="h4" gutterBottom>
              Welcome to Your Meeting Assistant Dashboard!
            </Typography>
            <Typography variant="body1" paragraph>
              Here you'll find an overview of your upcoming meetings, 
              recent summaries, and action items.
            </Typography>
          </Box>
        </Grid>
        {/* You can add more Grid items here for other dashboard content */}
        <Grid item xs={12}> 
          {/* <UpcomingMeetings />  */}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;