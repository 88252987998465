// src/components/Profile.js
import React from 'react';
import Typography from '@mui/material/Typography';
import LinkZoomButton from './LinkZoomButton'; // Assuming you have the LinkZoomButton component

const Profile = () => {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Your Profile
      </Typography>

      {/* Add other profile information or settings here */}
      {/* <LinkZoomButton />  */}
    </div>
  );
};

export default Profile;