// src/theme.js
import { createTheme } from '@mui/material/styles';

export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    primary: {
      main: mode === 'dark' ? '#BB86FC' : '#6200EE',
    },
    secondary: {
      main: mode === 'dark' ? '#03DAC6' : '#018786',
    },
    background: {
      default: mode === 'dark' ? '#121212' : '#fff',
      paper: mode === 'dark' ? '#1E1E1E' : '#fff',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

// You can export a default dark theme if you want
export const darkTheme = createTheme(getDesignTokens('dark'));